
import { useGrosseryStore } from '@/stores/grossery'
import { useMarketStore } from '~/stores/market'
import goToCheckout from '@/mixins/goToCheckout'
import { useCustomer } from '@/stores/customer'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'GrosseryCart',
  mixins: [goToCheckout],
  setup() {
    const marketStore = useMarketStore()
    const grosseryStore = useGrosseryStore()
    const customerStore = useCustomer()
    const uiStore = useUiStore()
    const checkoutCredit = useCheckoutCredit()
    return {
      marketStore,
      customerStore,
      uiStore,
      checkoutCredit,
      grosseryStore,
    }
  },
  data() {
    return {
      selected_parnters: 'ather_parnter',
      keenSliderOptions: {
        breakpoints: {
          '(min-width: 400px)': {
            slides: { perView: 4, spacing: 5 },
          },
          '(min-width: 768px)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(min-width: 1000px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(min-width: 900px)': {
            slides: { perView: 11, spacing: 8 },
          },
        },
        slides: { perView: 4, spacing: 8 },
      },
    }
  },
  computed: {
    hideNavAround() {
      return this.listAtherPartners?.length >= (this.$device.isMobile ? 4 : 10)
    },
    hideNavAroundGroserry() {
      return (
        this.listGroserryPartners?.length >= (this.$device.isMobile ? 4 : 10)
      )
    },
    listGroserryPartners() {
      const partners =
        this.marketStore.cart?.sellers.filter(
          partner => partner?.id == this.grosseryStore.grossery_merchant_uid,
        ) || []
      return this.mixPartners(partners)
    },
    listAtherPartners() {
      const partners =
        this.marketStore.cart?.sellers.filter(
          partner => partner?.id !== this.grosseryStore.grossery_merchant_uid,
        ) || []
      return this.mixPartners(partners)
    },
    ProductForLoan() {
      const products = this.marketStore.cart?.sellers
        ?.map(orders => orders?.line_items)
        .flat(1)
      return products?.filter(product => {
        return product?.loanable
      })
    },
  },
  beforeDestroy() {
    this.grosseryStore.showPopupChooseGrosseryCheckout = false
    this.grosseryStore.showPopupChooseGrosseryCheckoutCredit = false
    this.grosseryStore.showPopupCheckoutCreditNotAvailable = false
  },
  methods: {
    mixPartners(partners) {
      const products =
        partners
          .map(partner => {
            return partner.line_items.map(item => {
              return {
                product_id: item?.product_id,
                id: item?.id,
                image_url: item?.image_url,
                is_selected_for_checkout: item?.is_selected_for_checkout,
                state: item?.state,
                availability_check_needed: item?.availability_check_needed,
                availability_check_state: item?.availability_check_state,
              }
            })
          })
          .flat() || []
      return products.filter(product => {
        return (
          product.is_selected_for_checkout &&
          product.state === 'active' &&
          (product?.availability_check_needed == false ||
            product?.availability_check_state === 'AVAILABLE')
        )
      })
    },
    setLogoUrl(logo_url) {
      return logo_url || '/images/no-pos-found.svg'
    },
    async confirmCheckout() {
      if (this.selected_parnters === 'partner_grossery') {
        this.grosseryStore.updateTypeGrosseryCartMode('GROCERY')
      }
      if (this.selected_parnters === 'ather_parnter') {
        this.grosseryStore.updateTypeGrosseryCartMode('NOT_GROCERY')
      }
      await this.marketStore.getCart()
      this.grosseryStore.showPopupChooseGrosseryCheckout = false
      this.grosseryStore.continueCheckoutWithoutGrossery = true
    },
    async confirmCheckoutCredit() {
      // this.grosseryStore.updateTypeGrosseryCartMode('NOT_GROCERY')
      // await this.marketStore.getCart()
      await this.marketStore.setSelectForCheckout({
        line_item_ids: this.ProductForLoan.filter(item => item.loanable).map(
          item => item.id,
        ),
      })
      this.goToCreditCheckout(true)
      this.grosseryStore.showPopupChooseGrosseryCheckoutCredit = false
      // this.goToCreditCheckout(true)
      // this.grosseryStore.continueCreditCheckoutWithoutGrossery = true // REDIRECT TO CHECKOUT
    },
  },
}
